<template>
  <div>
    <section id="regFormSec" :style="{ minHeight: winH + 'px' }">
      <b-container>
        <b-row v-if="$route.name == 'index'">
          <b-col>
            <div class="title">
              <img src="/img/hero-title.svg" class="img-fluid" />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form id="regForm" @submit.prevent="submitForm" class="lato-r">
              <b-row>
                <b-col sm="10" md="6" class="">
                  <b-form-group
                    label="FIRST NAME"
                    label-for="regFnName"
                    label-sr-only
                  >
                    <b-form-input
                      id="regFnName"
                      type="text"
                      v-model="form.fn"
                      placeholder="FIRST NAME*"
                      aria-label="FIRST NAME (required)"
                      required
                      aria-labelledby="regFormSec regForm regFnName"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="10" md="6" class="">
                  <b-form-group
                    label="LAST NAME"
                    label-for="regLnName"
                    label-sr-only
                  >
                    <b-form-input
                      id="regLnName"
                      type="text"
                      v-model="form.ln"
                      placeholder="LAST NAME*"
                      aria-label="LAST NAME (required)"
                      required
                      aria-labelledby="regFormSec regForm regLnName"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col sm="10" md="6" class="">
                  <b-form-group label="CITY" label-for="regCity" label-sr-only>
                    <b-form-input
                      id="regCity"
                      type="text"
                      v-model="form.city"
                      placeholder="CITY"
                      aria-label="CITY"
                      aria-labelledby="regFormSec regForm regCity"
                    ></b-form-input>
                  </b-form-group>
                </b-col> -->
                <b-col sm="10" md="6" class="">
                  <b-form-group
                    label="E-MAIL ADDRESS"
                    label-for="regEmail"
                    label-sr-only
                  >
                    <b-form-input
                      id="regEmail"
                      type="email"
                      v-model="form.email"
                      placeholder="E-MAIL ADDRESS*"
                      aria-label="E-MAIL ADDRESS (required)"
                      required
                      aria-labelledby="regFormSec regForm regEmail"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="10" md="6" class="">
                  <b-form-group
                    label="PHONE NUMBER"
                    label-for="regPhone"
                    label-sr-only
                  >
                    <b-form-input
                      id="regPostal"
                      type="text"
                      v-model="form.phone"
                      placeholder="PHONE NUMBER*"
                      aria-label="PHONE NUMBER (required)"
                      required
                      aria-labelledby="regFormSec regForm regPhone"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="10" md="6" class="">
                  <b-form-group
                    label="POSTAL CODE"
                    label-for="regPostal"
                    label-sr-only
                  >
                    <b-form-input
                      id="regPostal"
                      type="text"
                      v-model="form.postalCode"
                      placeholder="POSTAL CODE*"
                      aria-label="POSTAL CODE (required)"
                      required
                      aria-labelledby="regFormSec regForm regPostal"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                
              </b-row>
              <b-row>
                <b-col sm="10" md="6" class=""> ARE YOU AN AGENT? </b-col>
              </b-row>
              <b-row>
                <b-col sm="10" md="6" class="">
                  <b-form-group
                    label="ARE YOU AN AGENT?"
                    label-for="regAgent"
                    label-sr-only
                  >
                    <b-form-select
                      id="regAgent"
                      type="text"
                      v-model="form.agent"
                      :options="optionsAgent"
                      :class="{
                        hasValue: form.agent,
                      }"
                      aria-label="Select ARE YOU AN AGENT? (required)"
                      required
                      aria-labelledby="regFormSec regForm regAgent"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="10" md="6" class="">
                  <b-form-group
                    label="AGENT NAME"
                    label-for="regAgentName"
                    label-sr-only
                    v-if="form.agent == 'Yes'"
                  >
                    <b-form-input
                      id="regAgentName"
                      type="text"
                      v-model="form.agentName"
                      placeholder="BROKERAGE NAME*"
                      aria-label="BROKERAGE NAME (required)"
                      :required="form.agent == 'Yes'"
                      aria-labelledby="regFormSec regForm regAgentName"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="10" md="6" class="" id="submitButton">
                  <b-form-group
                    aria-describedby="regFormSec regForm submitButton"
                    aria-labelledby="regFormSec regForm submitButton"
                  >
                    <b-button
                      type="submit"
                      aria-label="Submit Button"
                      aria-describedby="regFormSec regForm submitButton"
                      >SUBMIT</b-button
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <b-row>
          <b-col> *required fields </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div id="btmWrap">
              <a href="https://metropia.ca/" target="_blank" class="metropiaLogo">
                <div>
                  <img src="/img/metropia.svg" class="img-fluid" />
                </div>
              </a>
              <div class="socialLogos">
                <a
                  href="https://www.facebook.com/Metropia/"
                  target="_blank"
                  class="socialIcon"
                >
                  <div class="smLogo fb">
                    <img src="/img/facebook.png" class="img-fluid" /></div
                ></a>
                <!-- <a href="" target="_blank" class="socialIcon"><div class="smLogo">t</div></a> -->
                <a
                  href="https://www.instagram.com/metropiacommunities/"
                  target="_blank"
                  class="socialIcon"
                  ><div class="smLogo"><img src="/img/instagram.png" class="img-fluid" /></div></a
                >
              </div>
              <div class="text lato-r">
                * Renderings are artist’s
                impression. E. & O. E.
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import jq from "jquery";
export default {
  data() {
    return {
      winH: null,
      form: {
        fn: "",
        ln: "",
        city: "",
        postalCode: "",
        email: "",
        phone: "",
        agent: null,
        agentName: "",
      },
      optionsAgent: [
        {
          value: null,
          text: "Please Choose",
          disabled: true,
        },
        {
          value: "Yes",
          text: "Yes",
        },
        {
          value: "No",
          text: "No",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.winH = jq(window).height();

      jq(window).on("resize", () => {
        this.winH = jq(window).height();
      });
    });
  },
  methods: {
    submitForm() {
      let _this = this;
      let form = _this.form;
      form.postalCode = form.postalCode.toUpperCase();
      jq.ajax({
        method: "POST",
        url: "/php/registration.php",
        crossDomain: true,
        data: {
          email: form.email,
          firstname: form.fn,
          lastname: form.ln,
          // city: form.city,
          postal_code: form.postalCode,
          phone_number: form.phone,
          Areyouabroker: form.agent,
          agentName:form.agentName,
        },
        success: function (data) {
          // _this.$router.push({ path: "/thankyou" });
          jq.ajax({
            method: "POST",
            url: "/php/registration_CRM.php",
            crossDomain: true,
            data: {
              email: form.email,
              firstname: form.fn,
              lastname: form.ln,
              // city: form.city,
              postal_code: form.postalCode,
              phone_number: form.phone,
              Areyouabroker: form.agent,
              agentName:form.agentName,
            },
            success: function (data) {
              _this.$router.push({ path: "/thankyou" });
            },
          }).done(function (msg) {
            console.log("done CRM");
            // console.log(msg);
          });
        },
      }).done(function (msg) {
        console.log("done c.m.");
        // console.log(msg);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
#regFormSec {
  background-color: $darkGrey;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    width: 35%;
    margin: 4rem auto;
    @media screen and (max-width:450px){
      width: 55%;
      margin: 4rem auto;
    }
  }
  #btmWrap {
    .socialLogos {
      a.socialIcon {
        .smLogo {
          color: $white;
        }
        .fb{
          width:20%;
          text-align: center;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "./assets/styles/variables";
#regFormSec {
  #regForm {
    font-size: 1.25rem;
    ::-webkit-input-placeholder {
      /* Edge */
      color: $white;
      opacity: 0.5;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $white;
      opacity: 0.5;
    }

    ::placeholder {
      color: $white;
      opacity: 0.5;
    }
    .form-control {
      border-radius: 0rem;
      background-color: $darkGrey;
      border: none;
      border-bottom: solid 2px $white;
      margin-top: 1rem;
      color: $white;
      opacity: 1;
      padding-left: 0;
      font-size: 1.25rem;
    }
    #regAgentName.form-control{
      margin-top:0;
    }
    .custom-select {
      color: rgba($color: $white, $alpha: 0.5);
      border-radius: 0rem;
      background-color: $darkGrey;
      border: none;
      border-bottom: solid 2px $white;
      padding-left: 0;
      background: url("/img/caret-down-solid.svg");
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: right center;
      font-size: 1.25rem;
    }
    .custom-select.hasValue {
      color: rgba($color: $white, $alpha: 1);
    }
    #submitButton {
      button {
        font-size: 1.25rem;
        width: 100%;
        background-color: $lightGrey;
        border-radius: 0rem;
      }
    }
  }
  #btmWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 0px;
    .metropiaLogo {
      width: 40%;
      max-width: 200px;
      margin: 1rem auto;
    }
    .socialLogos {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .smLogo {
        width: 30px;
        height: 30px;
        margin: 10px;
      }
    }
    .text {
      font-size: 0.75rem;
    }
  }
}
</style>
